<template>
  <div class="student-item">
    <div class="i-container">
      <!-- 搜索选项 -->
      <el-row class="i-select">
        <ul class="s-contianer">
          <li class="s-item">
            <div class="s-title">性别：</div>
            <div>
              <el-select v-model="selectListVal.gender" size="small" clearable @change="genderStatus">
                <el-option v-for="(item, index) in genderList" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>
            </div>
          </li>
          <li class="s-item">
            <div class="s-title">身高：</div>
            <div>
              <el-select v-model="selectListVal.height" size="small" clearable @change="heightStatus">
                <el-option v-for="(item, index) in heightList" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>
            </div>
          </li>
          <li class="s-item">
            <div class="s-title">年龄：</div>
            <div>
              <el-select v-model="ageInputVal" size="small" clearable @change="ageStatus">
                <el-option v-for="(item, index) in ageList" :key="index" :label="item"
                  :value="{ value: index, label: item }">
                </el-option>
              </el-select>
            </div>
          </li>
          <li class="s-item">
            <div class="s-title">学历：</div>
            <div>
              <el-select v-model="selectListVal.education_level" size="small" clearable @change="educationStatus">
                <el-option v-for="(item, index) in educationList" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>
            </div>
          </li>
          <li class="s-item">
            <div class="s-title">兵役史：</div>
            <div>
              <el-select v-model="selectListVal.army_service_history" size="small" clearable
                @change="armyHistoryStatus">
                <el-option v-for="(item, index) in armyHistoryList" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>
            </div>
          </li>

        </ul>
        <ul class="s-contianer" style="margin-top: 10px;">
            <li class="s-item">
            <div class="s-title">证书：</div>
            <div>
              <el-select v-model="selectListVal.pid" size="small" clearable multiple @change="certStaffStatus">
                <el-option v-for="(item, index) in certNameList" :key="index" :label="item.name" :value="index">
                </el-option>
              </el-select>
            </div>
          </li>
          <!-- <li class="s-item">
            <div class="s-title">消防证：</div>
            <div>
                <el-select v-model="selectListVal.cert_fire" size="small" clearable @change="certFireStatus">
                <el-option label="是" :value="1"></el-option>
                <el-option label="否" :value="0"></el-option>
              </el-select>
            </div>
          </li> -->
        </ul>
        <div class="i-empty">
          <el-button type="text" @click="emptySelectValue()">清空筛选条件</el-button>
        </div>
      </el-row>
      <!-- 学员列表 -->
      <el-row v-loading="loading">
        <div v-for="(item, index) in stdInfo" :key="index" @click="goStudentDetail(item.user_id)">
          <el-row class="i-list">
            <el-col :span="3" class="l-col">
              <el-image :src="imageSrc(item.user_avatar, item.gender)"></el-image>
              <div class="Real" v-if="item.user_auth_state == 3">
                <span>已实名</span>
              </div>
            </el-col>
            <el-col :span="17" class="l-col">
              <div class="i-info">
                <div class="i-name">{{item.user_nickname}}</div>
                <el-row class="i-content">
                  <el-col :span="12" style="width:100%">
                    <ul class="c-info">
                      <li class="i-item">{{ item.gender | genderFormat }}</li>
                      <li class="i-item" v-if="item.id_cart_num">{{ item.id_cart_num }}岁</li>
                      <li class="i-item" v-if="item.height">{{ item.height }}cm</li>
                      <li class="i-item" v-if="item.min_salary_range == 0 || item.min_salary_range == null ">面议</li>
                      <li class="i-item" v-else>{{item.min_salary_range}}-{{item.max_salary_range}}k</li>
                      <li class="i-item" v-if="item.education_level">{{ item.education_level }}</li>
                      <li class="i-item" v-if="item.job_wanted == 1">正在找工作</li>
                      <li class="i-item" v-if="item.job_wanted == 0">暂不找工作</li>
                      <!-- <li class="i-item" v-if="item.job_wanted == 2">在校生兼职</li> -->
                      <!-- <li class="i-item" v-if="item.education_level">{{ item.education_level }}</li> -->
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <!-- <div class="i-exp mt5">2021.03-2023.08 十二方研究院*程序员</div> -->
                  </el-col>
                </el-row>
              </div>
              <div class="certName"><span v-for="(items,indexs) in item.certName" :key="indexs" class="cert">{{items}}</span></div>
            </el-col>
            <el-col :span="5" class="l-col">
              <div v-if="item.user_phone" class="userPhone">{{item.user_phone}}</div>
              <div v-else><look-phone :userId="item.user_id"></look-phone></div>              
            </el-col>
          </el-row>
        </div>
        <!-- 分页功能 -->
        <el-row class="l-pagination">
          <!-- <custom-page :page.sync="page" :limit.sync="limit" :total="count" @getList="getStudentList" /> -->
          <Pagination :page.sync="page" :limit.sync="limit" :total="count" @getList="getStudentList" />
        </el-row>
      </el-row>
    </div>
  </div>
</template>

<script>
  import {
    dropDownSearchStudentApi,
    selectInitJobApi
  } from '@/api/home'
  // import customPage from '@/components/Pagination/customPage.vue'
  import Pagination from '@/components/Pagination'
  import lookPhone from '@/components/lookPhone/index.vue'
  export default {
    name: "studentItem",
    components: {
      Pagination,
      lookPhone
    },
    data() {
      return {
        stdInfo: [],
        loading: false,
        page: 1,
        limit: 5,
        count: 0,
        selectListVal: {
          gender: '',
          height: '',
          education_level: '',
          army_service_history: '',
          minDay: '',
          maxDay: '',
          // certificate: '',
          pid: [],
        },
        ageInputVal: '',
        //下拉选择项
        educationList: {}, //学历列表
        certNameList: {},
        genderList: {
          "0": "女",
          "1": "男",
        },

        heightList: {
          "155": "155cm以上",
          "160": "160cm以上",
          "165": "165cm以上",
          "170": "170cm以上",
          "175": "175cm以上",
          "180": "180cm以上",
        },
        armyHistoryList: {
          "0": "否",
          "1": "是",
        },
        ageList: {
          "0": '不限',
          "1": "16-20岁",
          "2": "21-30岁",
          "3": "31-40岁",
          "4": "41-50岁",
          "5": "51-60岁",
          "6": "60岁以上",
        },
        ageMapList: [{
            id: '0',
            ageVal: []
          },
          {
            id: '1',
            ageVal: [16, 20]
          },
          {
            id: '2',
            ageVal: [21, 30]
          },
          {
            id: '3',
            ageVal: [31, 40]
          },
          {
            id: '4',
            ageVal: [41, 50]
          },
          {
            id: '5',
            ageVal: [51, 60]
          },
          {
            id: '6',
            ageVal: [61, 70]
          }
        ],
      }
    },
    filters: {
      genderFormat(val) {
        if (val == 1) {
          return val = '男'
        } else if (val == 2) {
          return val = '未知'
        } else {
          return val = '女'
        }
      }
    },
    watch: {
      selectListVal: {
        handler(val) {
          this.selectListVal = val
          this.page = 1
          this.getStudentList()
        },
        deep: true
      }
    },
    created() {
      this.getStudentList()
      this.getSelectInit()
    },
    methods: {
      //下拉数据初始化
      async getSelectInit() {
        const {
          data: res
        } = await selectInitJobApi()
        if (res.status != 10000) {
          return this.$message.error('下拉列表数据初始化失败')
        }
        this.certNameList = res.result.certName
        this.educationList = res.result.education
      },
      //全部学员列表
      async getStudentList() {
        this.loading = true
        const {
          data: res
        } = await dropDownSearchStudentApi(this.selectListVal, this.page, this.limit)
        if (res.status != 10000) {
          return this.$message.error('获取学员列表数据失败')
        }
        this.stdInfo = res.result.list
        let stdCount = res.result.count
        // if (stdCount > 50) {
        //   this.count = 50
        // } else {
        //   this.count = stdCount
        // }
        this.count = stdCount
        this.loading = false
      },
      genderStatus(val) {
        this.selectListVal.gender = val
      },
      heightStatus(val) {
        this.selectListVal.height = val
      },
      educationStatus(val) {
        this.selectListVal.education_level = val
      },
      armyHistoryStatus(val) {
        this.selectListVal.army_service_history = val
      },
      certificateStatus(val) {
        this.selectListVal.certificate = val
      },
      certStaffStatus(val){
        this.selectListVal.pid=val
      },
      certFireStatus(val){
        this.selectListVal.cert_fire=val
      },
      ageStatus(params) {
        const {
          value,
          label
        } = params
        this.ageInputVal = label
        let val = value
        let ageVal = ''
        this.ageMapList.forEach(item => {
          if (item.id == val) {
            ageVal = item.ageVal
          }
        })
        this.selectListVal.minDay = ageVal[0]
        this.selectListVal.maxDay = ageVal[1]
      },
      //清空下拉选择项
      emptySelectValue() {
        this.selectListVal.gender = ''
        this.selectListVal.height = ''
        this.selectListVal.education_level = ''
        this.selectListVal.army_service_history = ''
        this.selectListVal.minDay = ''
        this.selectListVal.maxDay = ''
        this.ageInputVal = ''
        this.selectListVal.pid = []
      },
      //跳转至学员详情页
      goStudentDetail(user_id) {
        this.$router.push({
          name: 'studentDetail',
          params: {
            user_id: user_id
          }
        })
      },
      imageSrc(imgUrl, gender) {
        if (imgUrl) {
          return imgUrl
        } else if (imgUrl == null && gender == 0) {
          return require('../../../../assets/images/woman.jpg')
        } else if (imgUrl == null && gender == 1) {
          return require('../../../../assets/images/man.jpg')
        }
      },
    },
  }

</script>

<style lang="less" scoped>
  /deep/.el-dialog__header {
    background: #fff !important;
  }

  /deep/.el-select {
    width: 100%;
  }

  /deep/.el-select .el-input__inner:focus {
    border-color: @primaryColor;
  }

  .student-item {
    width: 1200px;
    height: auto;
    margin: 0px auto;    

    .i-container {
      width: 1200px;
    }

    .i-list {
      margin-bottom: 20px;
      width: 1200px;
      min-height: 120px;
      background: #ffffff;
      border-radius: 8px;
      transition: box-shadow 1s;
      padding: 0px 40px;
      display: flex;
      align-items: center;

      &:hover {
        box-shadow: 2px 5px 10px gray;
      }

      .el-image {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        vertical-align: middle;
        margin: -3px 0 0 10px;
        border: none;
      }

      .i-name {
        font-weight: 600;
      }

      .i-content {
        .c-info {
          display: flex;
          color: #8d92a1;
          margin-top: 10px;
          font-size: 12px;

          .i-item {
            // border-left: 1px solid #8d92a1;
            margin-right: 10px;
            // height: 12px;
            line-height: 1.5;
          }

          .i-item:last-child {
            margin-right: 0px;
            border-left: none;
          }
        }

        .i-exp {
          margin-bottom: 5px;
          font-size: 13px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .l-col {
        height: 100%;
        padding: 20px;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
      }

      .i-info {
        padding: 20px;
      }
    }

    .i-select {
      padding-top:20px;
      padding-bottom: 20px;
      position: relative;

      .s-contianer {
        display: flex;
      }

      .s-item {
        .s-title {
          margin-bottom: 10px;
        }

        /deep/ .el-input__inner {
          color: @primaryColor;
        }

        /deep/.el-select {
          width: 90%;
          border-color: @primaryColor;
        }

        /deep/.el-input {
          border-color: @primaryColor;
        }
      }
    }

    .i-empty {
      position: absolute;
      bottom: 10px;
      right: 12%;
      text-align: right;

      .el-button {
        font-size: 15px;
      }
    }

    /deep/ .el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__wrapper {
      top: 25%;
    }

    .l-pagination {
      text-align: center;
      margin-bottom: 20px;
    }

    .userPhone{
      text-align: center;
      font-size: 16px;
      color: red;
      font-weight: bold;
    }

    .certName{
      padding: 0 20px 20px;

      .cert{
        display: inline-block;
        background: #f56c6c;
        color: #fff;
        padding: 5px 8px;
        border-radius: 8px;
        font-size: 12px;
        margin: 0 6px 0 0;
      }
    }

    .Real span{
      display: inline-block;
      padding: 1px 5px;
      color: #0092ff;
      font-size: 12px;
      line-height: 1.5;
      border: 1px solid #EBEEF5;
      margin: 5px 0 0 8px;
    }
  }

</style>
